import { Collapse } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { ServiceConfigsApi } from 'src/api'
import { EServiceConfigServiceType } from 'src/constants'
import { TServiceConfig } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { EPaths } from 'src/router'
import { BreadcrumbService, MessageService } from 'src/services'
import { ServiceConfig } from './service-config'

const View: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const [serviceConfigs, setServiceConfigs] = useState<TServiceConfig[]>([])
  const [/* loading */, setLoading] = useState(false)

  const fetchServiceConfigs = useCallback(() => {
    setLoading(true)
    from(ServiceConfigsApi.serviceConfigs({}))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe((res) => setServiceConfigs(res.edges?.map((edge) => edge?.node as TServiceConfig) || []))
  }, [unsubscribe$])

  const items = useMemo(
    () => Object.values(EServiceConfigServiceType).map((serviceType) => {
      const serviceConfig = serviceConfigs.find((config) => config.serviceType === serviceType)
      return ({
        key: serviceType,
        label: <span className="text-uppercase">{serviceType}</span>,
        children: (
          <ServiceConfig
            serviceType={serviceType}
            serviceConfig={serviceConfig}
            onUpdated={fetchServiceConfigs}
          />
        )
      })
    }),
    [serviceConfigs, fetchServiceConfigs]
  )

  useEffect(() => {
    fetchServiceConfigs()
  }, [fetchServiceConfigs])

  return (
    <section className="fx fx-column fx-extend">
      {!!items.length && (
        <Collapse items={items}/>
      )}
    </section>
  )
}

export const ServiceConfigs: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.SERVICE_CONFIGS,
      label: 'Service Configs'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return <View/>
}
