import { EPaths as AdminPaths } from 'src/modules/admin/routes.path'
import { EPaths as AuthPaths } from 'src/modules/auth/routes.path'
import { EPaths as BusinessDevelopmentPaths } from 'src/modules/business-development/routes.path'
import { EPaths as CompliancePaths } from 'src/modules/compliance/routes.path'
import { EPaths as CorePaths } from 'src/modules/core/routes.path'
import { EPaths as CSRPaths } from 'src/modules/csr/routes.path'
import { EPaths as EmailTemplatePaths } from 'src/modules/email-template/routes.path'
import { EPaths as MarketingPaths } from 'src/modules/marketing/routes.path'
import { EPaths as ServiceConfigsPaths } from 'src/modules/service-configs/routes.path'
import { EPaths as SettingPaths } from 'src/modules/setting/routes.path'

export const EPaths = {
  ...AdminPaths,
  ...AuthPaths,
  ...BusinessDevelopmentPaths,
  ...CompliancePaths,
  ...CorePaths,
  ...CSRPaths,
  ...EmailTemplatePaths,
  ...MarketingPaths,
  ...ServiceConfigsPaths,
  ...SettingPaths
}
