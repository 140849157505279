import { CopyOutlined } from '@ant-design/icons'
import { Button, Descriptions, DescriptionsProps, Tag } from 'antd'
import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { UserApi } from 'src/api'
import { TUser } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { DialogRemoveTOTP } from 'src/partials'
import { ClipboardService, MessageService } from 'src/services'
import { EventUtils } from 'src/utils'

interface IProps {
  user: TUser | null
  onRefresh?: () => void
}

export const UserProfile: FC<IProps> = ({ user, onRefresh }) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [emailConfirmed, setEmailConfirmed] = useState<boolean | null>(null)

  const onConfirmEmail = useCallback(async (e: SyntheticEvent, email: string) => {
    EventUtils.preventDefault(e)

    setLoading(true)
    from(UserApi.confirmRegistration({
      email
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Confirm email successfully')
        setEmailConfirmed(true)
      })
  }, [unsubscribe$])

  const items: DescriptionsProps['items'] = useMemo(() => ([
    {
      key: '1',
      label: 'Display name',
      children: user?.account.displayName
    },
    {
      key: '2',
      label: 'User ID',
      children: user?.id
        ? (
          <div className="fx fx-ai-center gap-1">
            {user.id}
            <CopyOutlined
              className="pointer"
              onClick={() => ClipboardService.copy(user.id)}
            />
          </div>
        )
        : '--'
    },
    {
      key: '3',
      label: 'Account ID',
      children: user?.account.id
        ? (
          <div className="fx fx-ai-center gap-1">
            {user.account.id}
            <CopyOutlined
              className="pointer"
              onClick={() => ClipboardService.copy(user.account.id)}
            />
          </div>
        )
        : '--'
    },
    {
      key: '4',
      label: 'First name',
      children: user?.firstName
    },
    {
      key: '5',
      label: 'Last name',
      children: user?.lastName
    },
    {
      key: '6',
      label: 'Email',
      children: user
        ? (
          <div className="fx gap-2 fx-ai-center">
            {user?.email}
            {emailConfirmed
              ? (
                <Tag color="success">Confirmed</Tag>
              )
              : (
                <Button
                  type="primary"
                  size="small"
                  disabled={loading}
                  onClick={(e) => onConfirmEmail(e, user?.email || '')}
                >
                  Confirm
                </Button>
              )}
          </div>
        )
        : '--'
    },
    {
      key: '7',
      label: 'Funding account address',
      children: user?.account?.chainAccountAddress
        ? (
          <div className="fx fx-ai-center gap-1">
            {user.account.chainAccountAddress}
            <CopyOutlined
              className="pointer"
              onClick={() => ClipboardService.copy(user.account.chainAccountAddress)}
            />
          </div>
        )
        : '--'
    },
    {
      key: '8',
      label: 'Trading account IDs',
      children: user?.account?.subAccounts?.map(({ chainSubAccountID }) => (
        <div className="fx fx-ai-center gap-1" key={chainSubAccountID}>
          {chainSubAccountID}
          <CopyOutlined
            className="pointer"
            onClick={() => ClipboardService.copy(chainSubAccountID)}
          />
        </div>
      ))
    },
    {
      key: '9',
      label: 'TOTP enabled',
      children: user?.totpEnabled
        ? (
          <div className="fx fx-ai-center gap-1">
            True
            <DialogRemoveTOTP user={user} btnProps={{ type: 'link' }} afterClose={(fresh) => fresh && onRefresh?.()}>
              Remove
            </DialogRemoveTOTP>

          </div>
        )
        : 'False'
    }
  ].map(el => ({
    ...el,
    labelStyle: {
      width: '50%'
    }
  }))), [user, emailConfirmed, onConfirmEmail, loading, onRefresh])

  useEffect(() => {
    setEmailConfirmed(user?.confirmed ?? null)
  }, [user])

  return (
    <section className="fx fx-column fx-extend">
      <Descriptions layout="horizontal" size="small" column={1} bordered items={items}/>
    </section>
  )
}
