import { EAccountOffChainPermission, TUser } from 'src/graphql'

export class UserUtils {
  static getFullName(user?: {
    firstName?: string | null
    lastName?: string | null
  }) {
    return [user?.firstName, user?.lastName].filter(Boolean).join(' ')
  }

  static isAdminBusiness(user?: TUser) {
    return user?.isBusinessUser && user.accountOffChainPermissionsExtended?.includes(EAccountOffChainPermission.ACC_OFF_CHAIN_ADMIN)
  }

  static copyUserCSRInfo(user?: TUser | null) {
    return [
      `Email: ${user?.email || ''}`,
      `Funding Account ID: ${user?.account.id || ''}`,
      `Trading Account IDs: ${user?.account.subAccounts.length ? user?.account.subAccounts?.map(({ chainSubAccountID }) => chainSubAccountID).join(', ') : ''}`,
      ''
    ].join('\n')
  }
}
