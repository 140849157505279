import { Input, Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Pagination } from 'src/atoms'
import { TUserOverview, UsersQuery, usersQuery } from 'src/graphql'
import { useBehaviorMapper, useDidMountDebounce } from 'src/hooks'
import { EPaths } from 'src/router'
import { BreadcrumbService, PaginationService } from 'src/services'
import { renderColumns } from './columns'

export const IndividualList: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.CSR_INDIVIDUAL,
      label: 'Individual'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const _paginationService = useMemo(() => new PaginationService<TUserOverview>(usersQuery), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.items$)

  const [filter, setFilter] = useState<{
    keyword: string
    accountID: string
  }>({ keyword: '', accountID: '' })

  const computedWhere = useMemo<UsersQuery['variables']['where']>(() => {
    const keywordTrimmed = filter.keyword.trim()
    let accountIDTrimmed = filter.accountID.trim()
    if (accountIDTrimmed && !accountIDTrimmed.startsWith('ACC:')) {
      accountIDTrimmed = `ACC:${accountIDTrimmed}`
    }

    return {
      or: keywordTrimmed
        ? [
          { emailContainsFold: keywordTrimmed },
          { firstNameContainsFold: keywordTrimmed },
          { lastNameContainsFold: keywordTrimmed }
        ]
        : [],
      ...(accountIDTrimmed ? { accountID: accountIDTrimmed } : {}),
      isBusinessUser: false
    }
  }, [filter])

  const fresh = useCallback(
    (params?: Parameters<typeof _paginationService.fetch>[0]) => _paginationService.fetch(params),
    [_paginationService]
  )

  useDidMountDebounce(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  }, 500, [computedWhere, fresh])

  useEffect(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input
            allowClear
            placeholder="Search by name & email"
            style={{ flex: '0 0 200px' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />
          <Input
            allowClear
            placeholder="Search by account ID"
            style={{ flex: '0 0 200px' }}
            readOnly={loading}
            value={filter.accountID}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              accountID: e.target.value
            }))}
          />
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        loading={loading}
        dataSource={dataSource}
        columns={renderColumns({ onFresh: fresh })}
        scroll={{ x: true }}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        canPrev={_paginationService.canPrev}
        canNext={_paginationService.canNext}
        onPrev={() => _paginationService.prev({ where: computedWhere })}
        onNext={() => _paginationService.next({ where: computedWhere })}
      />
    </section>
  )
}
