import { EPaths, IRouterOptions } from 'src/router'
import { ServiceConfigs } from './components'

export enum ERoutes {
  SERVICE_CONFIGS = 'SERVICE_CONFIGS'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.SERVICE_CONFIGS,
    name: ERoutes.SERVICE_CONFIGS,
    component: ServiceConfigs,
    meta: {
      requiredAuth: true
    }
  }
]

export const ServiceConfigsModule = {
  ERoutes,
  routes
}
