import { Button, Modal, Radio, RadioChangeEvent, Space } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AccountApi } from 'src/api'
import { EReferralLevel, EReferralLevelTxt } from 'src/graphql'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { MessageService } from 'src/services'
import { EventUtils } from 'src/utils'

export const DialogReferralLevel: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  uid?: string
  value?: string
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
  accountID?: string
  referralLevelOptions?: string[]
}> = ({
  uid,
  btnProps,
  accountID,
  referralLevelOptions = [
    EReferralLevel.AGENT,
    EReferralLevel.AFFILIATE,
    EReferralLevel.SUB_AFFILIATE,
    EReferralLevel.ADVOCATE,
    EReferralLevel.USER
  ],
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState(true)
  const [value, setValue] = useState<string | null>(null)
  const afterCloseRef = useAsRef(props.afterClose)

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (props.value && !invisible) {
      setValue(props.value)
    }

    return () => {
      setValue(null)
    }
  }, [props.value, invisible])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    if (!value || !accountID) {
      return
    }

    setLoading(true)
    from(AccountApi.update({
      input: {
        accountID,
        referralTier: value as EReferralLevel
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Referral level updated successfully')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [accountID, afterCloseRef, unsubscribe$, value])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Referral level'}
      </Button>

      <Modal
        title={props.title || 'Referral level'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            {referralLevelOptions.map(el => (
              <Radio key={el} value={el}>{EReferralLevelTxt[el]}</Radio>
            ))}
          </Space>
        </Radio.Group>
      </Modal>
    </>
  )
}
