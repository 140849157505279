/**
 * @generated SignedSource<<7d5aeee31b7f91cdd4bb7589ace96daa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServiceConfigServiceType = "dfns" | "privy" | "xy_finance" | "%future added value";
export type ServiceConfigWhereInput = {
  and?: ReadonlyArray<ServiceConfigWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  enableDomains?: string | null;
  enableDomainsContains?: string | null;
  enableDomainsContainsFold?: string | null;
  enableDomainsEqualFold?: string | null;
  enableDomainsGT?: string | null;
  enableDomainsGTE?: string | null;
  enableDomainsHasPrefix?: string | null;
  enableDomainsHasSuffix?: string | null;
  enableDomainsIn?: ReadonlyArray<string> | null;
  enableDomainsLT?: string | null;
  enableDomainsLTE?: string | null;
  enableDomainsNEQ?: string | null;
  enableDomainsNotIn?: ReadonlyArray<string> | null;
  id?: string | null;
  idContainsFold?: string | null;
  idEqualFold?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: ServiceConfigWhereInput | null;
  or?: ReadonlyArray<ServiceConfigWhereInput> | null;
  serviceType?: ServiceConfigServiceType | null;
  serviceTypeIn?: ReadonlyArray<ServiceConfigServiceType> | null;
  serviceTypeNEQ?: ServiceConfigServiceType | null;
  serviceTypeNotIn?: ReadonlyArray<ServiceConfigServiceType> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type ServiceConfigsQuery$variables = {
  after?: any | null;
  before?: any | null;
  first?: number | null;
  last?: number | null;
  where?: ServiceConfigWhereInput | null;
};
export type ServiceConfigsQuery$data = {
  readonly serviceConfigs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly enableDomains: string;
        readonly id: string;
        readonly serviceType: ServiceConfigServiceType;
      } | null;
    } | null> | null;
  };
};
export type ServiceConfigsQuery = {
  response: ServiceConfigsQuery$data;
  variables: ServiceConfigsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "before",
        "variableName": "before"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "ServiceConfigConnection",
    "kind": "LinkedField",
    "name": "serviceConfigs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceConfigEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceConfig",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableDomains",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceConfigsQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ServiceConfigsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "173eabbe05b167de52d162dc152669e2",
    "id": null,
    "metadata": {},
    "name": "ServiceConfigsQuery",
    "operationKind": "query",
    "text": "query ServiceConfigsQuery(\n  $after: Cursor\n  $first: Int\n  $before: Cursor\n  $last: Int\n  $where: ServiceConfigWhereInput\n) {\n  serviceConfigs(after: $after, first: $first, before: $before, last: $last, where: $where) {\n    edges {\n      node {\n        id\n        serviceType\n        enableDomains\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbc5624282c6d04ccedc440d07886611";

export default node;
