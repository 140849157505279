import { FC, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { takeUntil } from 'rxjs'
import { useBehaviorMapper, useUnsubscribeEffect } from 'src/hooks'
import { DialogService } from 'src/services/dialog.service'

type TItem = TArrayItem<typeof DialogService.items$.value>

const DialogItem: FC<{ item: TItem }> = ({ item }) => {
  const [, setLastUpdate] = useState<number>()
  const [hidden, setHidden] = useState(item.options.hidden ?? false)

  useUnsubscribeEffect((unsubscribe$) => {
    item.update$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => setHidden(value.hidden ?? false))
  }, [item.update$])

  useUnsubscribeEffect((unsubscribe$) => {
    item.props$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(() => setLastUpdate(Date.now()))
  }, [item.props$])

  const Dialog = useMemo(
    () => item.component,
    [item.component]
  )

  return (
    <div className={hidden ? 'hidden' : undefined}>
      <Dialog {...item.props}/>
    </div>
  )
}

export const DialogContainer: FC = () => {
  const location = useLocation()
  const dialogs = useBehaviorMapper(DialogService.items$)

  useEffect(() => {
    return () => {
      for (const item of DialogService.items$.value) {
        if (!item.options.persist) {
          item.unmount()
        }
      }
    }
  }, [location.pathname])

  return (
    <>
      {dialogs.map((item) => (
        <DialogItem
          key={item.id}
          item={item}
        />
      ))}
    </>
  )
}
