import { graphql } from 'relay-runtime'

import { SetServiceConfigMutation } from './__generated__/SetServiceConfigMutation.graphql'

export type { SetServiceConfigMutation }

export const setServiceConfigMutation = graphql`
  mutation SetServiceConfigMutation($input: SetServiceConfigInput!) {
    setServiceConfig(input: $input) {
      code
      message
    }
  }
`
