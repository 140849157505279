import { CopyOutlined } from '@ant-design/icons'
import { Descriptions, DescriptionsProps } from 'antd'
import { FC, useMemo } from 'react'
import { TUser } from 'src/graphql'
import { ClipboardService } from 'src/services'

interface IProps {
  user: TUser | null
  onRefresh?: () => void
}

export const AccountDetails: FC<IProps> = ({ user }) => {
  const transferAddressBooks = useMemo(() => {
    return (user?.account.transferRecipients || []).map((el) => el.chainAccountAddress)
  }, [user])

  const withdrawalAddressBooks = useMemo(() => {
    return (user?.account.withdrawalAddresses || []).map((el) => el.address)
  }, [user])

  const items: DescriptionsProps['items'] = useMemo(() => ([
    {
      key: '1',
      label: 'Email',
      children: user?.email
        ? (
          <div className="fx fx-ai-center gap-1">
            {user.email}
            <CopyOutlined
              className="pointer"
              onClick={() => ClipboardService.copy(user.email)}
            />
          </div>
        )
        : '--'
    },
    {
      key: '2',
      label: 'Account ID',
      children: user?.account.id
        ? (
          <div className="fx fx-ai-center gap-1">
            {user.account.id}
            <CopyOutlined
              className="pointer"
              onClick={() => ClipboardService.copy(user.account.id)}
            />
          </div>
        )
        : '--'
    },
    {
      key: '3',
      label: 'Trading account IDs',
      children: user?.account?.subAccounts?.map(({ chainSubAccountID }) => (
        <div className="fx fx-ai-center gap-1" key={chainSubAccountID}>
          {chainSubAccountID}
          <CopyOutlined
            className="pointer"
            onClick={() => ClipboardService.copy(chainSubAccountID)}
          />
        </div>
      ))
    },
    {
      key: '4',
      label: 'Withdrawal address',
      children: withdrawalAddressBooks?.map((address) => (
        <div className="fx fx-ai-center gap-1" key={address}>
          {address}
          <CopyOutlined
            className="pointer"
            onClick={() => ClipboardService.copy(address)}
          />
        </div>
      ))
    },
    {
      key: '5',
      label: 'Transfer address',
      children: transferAddressBooks.map((address) => (
        <div className="fx fx-ai-center gap-1" key={address}>
          {address}
          <CopyOutlined
            className="pointer"
            onClick={() => ClipboardService.copy(address)}
          />
        </div>
      ))
    }
  ].map(el => ({
    ...el,
    labelStyle: {
      width: '50%'
    }
  }))), [user, transferAddressBooks, withdrawalAddressBooks])

  return (
    <section className="fx fx-column fx-extend">
      <Descriptions layout="horizontal" size="small" column={1} bordered items={items}/>
    </section>
  )
}
