import { Button, Descriptions } from 'antd'
import { FC } from 'react'
import { EServiceConfigServiceType } from 'src/constants'
import { TServiceConfig } from 'src/graphql'
import { DialogEditEnabledDomains } from 'src/partials'
import { DialogService } from 'src/services'

interface IProps {
  serviceType: EServiceConfigServiceType
  serviceConfig?: TServiceConfig
  onUpdated?: () => void
}

export const ServiceConfig: FC<IProps> = ({ serviceType, serviceConfig, onUpdated }) => {
  return (
    <div className="fx-column">
      <Descriptions
        bordered
        items={[
          {
            label: (
              <div className="fx fx-ai-center fx-jc-space-between">
                <span>Enable status</span>
                <Button
                  type="link"
                  size="small"
                  onClick={() => DialogService.open(DialogEditEnabledDomains, {
                    serviceType,
                    serviceConfig,
                    afterClose(saved?: boolean) {
                      saved && onUpdated?.()
                    }
                  })}
                >
                  Edit
                </Button>
              </div>
            ),
            children: serviceConfig?.enableDomains && serviceConfig?.enableDomains !== '0'
              ? serviceConfig?.enableDomains === '1'
                ? 'Open for All'
                : serviceConfig?.enableDomains.split(',').join(', ')
              : 'Disable for All'
          }
        ]}
        layout="vertical"
      />
    </div>
  )
}
