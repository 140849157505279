import { graphql } from 'relay-runtime'

import { UserDetailsQuery } from './__generated__/UserDetailsQuery.graphql'

export type { UserDetailsQuery }

export type TUser = DeepWriteable<DeepExclude<TArrayItem<UserDetailsQuery['response']['users']['edges']>, null>['node']>

export enum EAccountOffChainPermission {
  ACC_OFF_CHAIN_ADMIN = 'ACC_OFF_CHAIN_ADMIN',
  ACC_OFF_CHAIN_OPS = 'ACC_OFF_CHAIN_OPS',
  ACC_OFF_CHAIN_USER = 'ACC_OFF_CHAIN_USER',
  ACC_OFF_CHAIN_USER_MANAGEMENT = 'ACC_OFF_CHAIN_USER_MANAGEMENT'
}

export const userDetailsQuery = graphql`
  query UserDetailsQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: UserWhereInput
  ) {
    users(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...on User {
            id
            updateTime
            email
            firstName
            lastName
            isBusinessUser
            confirmed
            accountID
            accountOffChainPermissionsExtended
            totpEnabled
            adminActionLogs {
              ...on AdminActionLog {
                createTime
                updateTime
                action
                comment
                adminPortalUser {
                  ...on AdminPortalUser {
                    email
                  }
                }
              }
            }
            account {
              ...on Account {
                id
                isBusiness
                feeLevel
                name
                displayName
                referralTier
                referralCode
                chainAccountAddress
                subAccounts {
                  ...on SubAccount {
                    chainSubAccountID
                  }
                }
                referringAccount {
                  ...on Account {
                    id
                    name
                    displayName
                    referralCode
                  }
                }
                state
                stateUpdatedAt
                accountStateTransitions {
                  ...on AccountStateTransition {
                    id
                    createTime
                    updateTime
                    accountID
                    fromState
                    toState
                    reason
                    comment
                    initiatedBy
                    initiatorType
                  }
                }
                users {
                  ...on User {
                      id
                      accountID
                      firstName
                      lastName
                      email
                      isBusinessUser
                      accountOffChainPermissionsExtended
                    }
                }
                transferRecipients {
                  ...on Account {
                    chainAccountAddress
                  }
                }
                withdrawalAddresses {
                  ...on WithdrawalAddressBook {
                    address
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
