import { Layout } from 'antd'
import { FC } from 'react'
import { Loading } from 'src/atoms'
import { useBehaviorMapper, useWatchTz } from 'src/hooks'
import { AuthService, LayoutService } from 'src/services'
import { CountriesStore } from 'src/store'
import { DialogContainer } from './dialog-container'
import { LayoutHeader } from './header'
import { RouterView } from './router-view'
import { LayoutSidebar } from './sidebar'
import Style from './style.module.scss'

export const AppLayout: FC = (props) => {
  // watch timezone
  useWatchTz()
  // watch supported countries
  useBehaviorMapper(CountriesStore.supportedCountries$)

  const isAuthenticated = useBehaviorMapper(AuthService.isAuthenticated$)
  const { loading } = useBehaviorMapper(LayoutService.layoutConfig$)

  return (
    <Layout className={Style.appLayout}>
      {isAuthenticated && <LayoutSidebar/>}

      <Layout.Content className="fx-1 fx fx-column">
        {isAuthenticated && <LayoutHeader/>}
        <RouterView/>
      </Layout.Content>

      <Loading show={loading || isAuthenticated === null}/>

      <DialogContainer/>
    </Layout>
  )
}
