import { Button, Input, Modal, Radio } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { ServiceConfigsApi } from 'src/api'
import { ErrorMessage } from 'src/atoms'
import { EServiceConfigServiceType } from 'src/constants'
import { TServiceConfig } from 'src/graphql'
import { useAsRef, useUnsubscribe, useValidation } from 'src/hooks'
import { MessageService } from 'src/services'
import { object, string } from 'yup'

const schema = object().shape({
  type: string().required('Type is required'),
  domains: string()
    .when('type', {
      is: '2',
      then: string()
        .required('Domains is required')
        .test('domains', 'Invalid domains', (value) => {
          if (!value) return false
          const domains = value.split(',').map((domain) => domain.trim()).join(',')
          return domains.length > 0
        })
    })
})

export const DialogEditEnabledDomains: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'onClose'| 'afterClose'
> & {
  serviceType: EServiceConfigServiceType
  serviceConfig?: TServiceConfig
  btnProps?: ComponentProps<typeof Button>
  onClose?: () => void
  afterClose?: (saved?: boolean) => any
}> = ({
  serviceType,
  serviceConfig,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(props.open || false)

  const onCloseRef = useAsRef(props.onClose)
  const close = useCallback(() => {
    setOpen(false)
    onCloseRef.current?.()
  }, [onCloseRef])

  useEffect(() => {
    setOpen(props.open || false)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const [formData, setFormData] = useState({
    type: !serviceConfig?.enableDomains || serviceConfig.enableDomains === '0'
      ? '0'
      : serviceConfig.enableDomains === '1'
        ? '1'
        : '2',
    domains: !serviceConfig?.enableDomains || serviceConfig.enableDomains === '0' || serviceConfig.enableDomains === '1'
      ? ''
      : serviceConfig.enableDomains.split(',').map((domain) => domain.trim()).join(', ')
  })

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  const submit = useCallback(async () => {
    const { isValid, value } = await validate()

    if (!isValid || !value) {
      return
    }

    const domains = value.type === '2'
      ? value.domains.split(',').map((domain) => domain.trim()).join(',')
      : value.type

    if (domains === serviceConfig?.enableDomains?.split(',').map((domain) => domain.trim()).join(',')) {
      MessageService.info('No changes made')
      close()
      return
    }

    setLoading(true)
    from(ServiceConfigsApi.setServiceConfig({
      input: {
        serviceType,
        enableDomains: domains
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Edit enable status successfully')
        close()
        afterCloseRef.current?.(true)
      })
  }, [unsubscribe$, afterCloseRef, serviceType, serviceConfig, close, validate])

  return (
    <>
      {props.children && (
        <Button
          type="primary"
          {...btnProps}
          onClick={close}
        >
          {props.children}
        </Button>
      )}

      {open && (
        <Modal
          title={props.title || 'Edit Enable Status'}
          open={open}
          centered
          closable={!loading} // display X icon
          keyboard={false} // disable close on press ESC
          maskClosable={false} // disable close on click outside
          okButtonProps={{ disabled: loading }}
          cancelButtonProps={{ disabled: loading }}
          okText="Ok"
          cancelText="Cancel"
          onOk={submit}
          onCancel={close}
        >
          <div className="fx fx-column gap-2">
            <div className="fx fx-column">
              <Radio.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 16
                }}
                value={formData.type}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    type: e.target.value
                  }))
                  reset()
                }}
              >
                <Radio value="0">Disable for all</Radio>
                <Radio value="1">Open for all</Radio>
                <Radio value="2">Open for selected domains</Radio>
              </Radio.Group>
              {errors.hasError('type') && (
                <ErrorMessage>{errors.getError('type')}</ErrorMessage>
              )}
            </div>
            {formData.type === '2' && (
              <div className="fx fx-column">
                <Input
                  value={formData.domains}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      domains: e.target.value
                    }))
                  }}
                  status={errors.hasError('domains') ? 'error' : undefined}
                  placeholder="e.g., @grvt.io, @abc.xyz"
                  autoFocus
                />
                {errors.hasError('domains') && (
                  <ErrorMessage>{errors.getError('domains')}</ErrorMessage>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}
