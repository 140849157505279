import { FC, useMemo } from 'react'
import { useParams } from 'react-router'
import { EPaths } from 'src/router'
import { IBreadcrumbItem } from 'src/services'
import { IndividualInfo } from '../../individual-info'

export const BusinessDetails: FC = () => {
  const { id } = useParams<{ id: string }>()

  const breadcrumb: IBreadcrumbItem[] = useMemo(() => ([
    {
      route: EPaths.CSR_BUSINESS,
      label: 'Business'
    },
    {
      route: [EPaths.CSR_BUSINESS_DETAIL, { id }],
      label: id
    }
  ]), [id])

  return (
    <IndividualInfo breadcrumb={breadcrumb} id={id}/>
  )
}
