import { Badge, Button, Collapse, CollapseProps } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { UserApi } from 'src/api'
import { EAccountState } from 'src/constants'
import { TUser } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { DialogChangeAccountState, UserChangelog } from 'src/partials'
import { AccountState } from 'src/partials/individual'
import { BreadcrumbService, ClipboardService, IBreadcrumbItem, MessageService } from 'src/services'
import { EventUtils, UserUtils } from 'src/utils'
import { AccountDetails } from './account-details'
import { ReferralInfo } from './referral-info'
import { BusinessUserList } from './user-list'
import { UserProfile } from './user-profile'

interface IProps {
  breadcrumb?: IBreadcrumbItem[]
  id?: string
}
export const IndividualInfo: FC<IProps> = ({ breadcrumb = [], id }) => {
  const unsubscribe$ = useUnsubscribe()
  const [user, setUser] = useState<TUser | null>(null)

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = breadcrumb
    return () => {
      BreadcrumbService.items = []
    }
  }, [breadcrumb])

  const stateBadge = useMemo(() => {
    return {
      [EAccountState.ACTIVE]: 'success',
      [EAccountState.CLOSED]: 'error',
      [EAccountState.DEACTIVATED]: 'error',
      [EAccountState.ON_HOLD]: 'warning'
    }[user?.account.state as EAccountState] || undefined
  }, [user?.account.state])

  const fetchUser = useCallback(() => {
    if (!id) return

    from(UserApi.find(id))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((user) => {
        setUser(user)
      })
  }, [id, unsubscribe$])

  const items = useMemo(
    () => ([
      {
        key: '1',
        label: 'User profile',
        children: <UserProfile user={user} onRefresh={fetchUser}/>
      },
      user?.isBusinessUser && {
        key: '2',
        label: 'User list',
        children: <BusinessUserList user={user} onFresh={fetchUser}/>
      },
      {
        key: '3',
        label: 'Account details',
        children: <AccountDetails user={user} onRefresh={fetchUser}/>,
        extra: (
          <Button
            size="small"
            type="link"
            onClick={(e) => {
              e.stopPropagation()
              ClipboardService.copy(UserUtils.copyUserCSRInfo(user))
            }}
          >
            Copy
          </Button>
        )
      },
      {
        key: '4',
        label: 'Referrals',
        children: <ReferralInfo user={user}/>
      },
      {
        key: '5',
        label: (
          <div className="fx fx-ai-center gap-2">
            Account state
            {!!(user?.account.state && stateBadge) && (
              <Badge
                status={stateBadge as ComponentProps<typeof Badge>['status']}
                text={user.account.state}
              />
            )}
          </div>
        ),
        children: <AccountState user={user}/>,
        extra: (
          <div onClick={e => EventUtils.stopPropagation(e)}>
            <DialogChangeAccountState
              user={user}
              btnProps={{ size: 'small', type: 'link' }}
              afterClose={(fresh) => fresh && fetchUser?.()}
            >
              Edit
            </DialogChangeAccountState>
          </div>
        )
      },
      {
        key: '6',
        label: 'Admin change log',
        children: <UserChangelog user={user}/>
      }
    ].filter(Boolean) as CollapseProps['items']),
    [user, stateBadge, fetchUser]
  )

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return (
    <section className="fx fx-column fx-extend">
      <Collapse
        items={items}
        defaultActiveKey={['1']}
      />
    </section>
  )
}
