import { graphql } from 'relay-runtime'

import { UsersQuery } from './__generated__/UsersQuery.graphql'

export type { UsersQuery }

export type TUserOverview = DeepWriteable<DeepExclude<TArrayItem<UsersQuery['response']['users']['edges']>, null>['node']>

export const usersQuery = graphql`
  query UsersQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: UserWhereInput
  ) {
    users(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
      orderBy: {
        field: CREATE_TIME
        direction: ASC
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...on User {
            id
            accountID
            updateTime
            email
            firstName
            lastName
            account {
              ...on Account {
                id
                name
                displayName
                feeLevel
                referralTier
                referralCode
                referringAccount {
                  ...on Account {
                    id
                    name
                    displayName
                    referralCode
                  }
                }
                state
              }
            }
          }
        }
      }
    }
  }
`
