import { graphql } from 'relay-runtime'

import { ServiceConfigsQuery } from './__generated__/ServiceConfigsQuery.graphql'

export type { ServiceConfigsQuery }

export type TServiceConfig = Writeable<NonNullable<NonNullable<TArrayItem<ServiceConfigsQuery['response']['serviceConfigs']['edges']>>['node']>>

export const serviceConfigsQuery = graphql`
  query ServiceConfigsQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: ServiceConfigWhereInput
  ) {
    serviceConfigs(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
      edges {
        node {
          id
          serviceType
          enableDomains
        }
      }
    }
  }
`
