import { ServiceConfigsQuery, SetServiceConfigMutation, serviceConfigsQuery, setServiceConfigMutation } from 'src/graphql'
import { EdgeGraphqlService } from 'src/services'

export class ServiceConfigsApi {
  /**
   * SECTION: Queries
   */

  static serviceConfigs(variables: ServiceConfigsQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<ServiceConfigsQuery>(serviceConfigsQuery, variables).then(
      (response) => response.serviceConfigs
    )
  }

  /**
   * SECTION: Mutations
   */

  static setServiceConfig(variables: SetServiceConfigMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<SetServiceConfigMutation>(setServiceConfigMutation, variables).then(
      (response) => response.setServiceConfig
    )
  }
}
