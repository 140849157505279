/**
 * @generated SignedSource<<4c3b03d8a35d46b48668a744016e51bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ServiceConfigServiceType = "dfns" | "privy" | "xy_finance" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type SetServiceConfigInput = {
  enableDomains: string;
  serviceType: ServiceConfigServiceType;
  settings?: string | null;
};
export type SetServiceConfigMutation$variables = {
  input: SetServiceConfigInput;
};
export type SetServiceConfigMutation$data = {
  readonly setServiceConfig: {
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type SetServiceConfigMutation = {
  response: SetServiceConfigMutation$data;
  variables: SetServiceConfigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BaseStatus",
    "kind": "LinkedField",
    "name": "setServiceConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetServiceConfigMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetServiceConfigMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "028b8b1858c67200649c3a8c7ed01e4f",
    "id": null,
    "metadata": {},
    "name": "SetServiceConfigMutation",
    "operationKind": "mutation",
    "text": "mutation SetServiceConfigMutation(\n  $input: SetServiceConfigInput!\n) {\n  setServiceConfig(input: $input) {\n    code\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b638f0e5fdf81917c88619baf80ab55";

export default node;
